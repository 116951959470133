import { forwardRef, JSXElementConstructor, ReactNode } from "react";
import { BaseUIComponentProps, defaultBaseUIComponentProps } from "./base";
import { cva, VariantProps } from "class-variance-authority";
import { useGlobalDefaults } from "./provider";
import { cn } from "@/lib/css-utils";

export type ParagraphFontWeight = "regular" | "medium";
export type ParagraphSize = "xxs" | "xs" | "sm" | "md";

const paragraphVariants = cva("font-roboto", {
  variants: {
    variant: {
      title: "",
      subtitle: "",
    },

    mode: {
      dark: "",
      light: "",
    },

    fontWeight: {
      regular: "font-normal",
      medium: "font-medium",
    },

    size: {
      md: "text-lg",
      sm: "text-md",
      xs: "text-sm",
      xxs: "text-xs",
    },
  },

  compoundVariants: [
    {
      mode: "dark",
      variant: "title",
      className: "text-token-text-light-default",
    },
    {
      mode: "light",
      variant: "title",
      className: "text-black",
    },

    {
      mode: "dark",
      variant: "subtitle",
      className: "text-token-text-subtitle-default",
    },
    {
      mode: "light",
      variant: "subtitle",
      className: "text-black",
    },
  ],

  defaultVariants: {
    mode: defaultBaseUIComponentProps.mode,
    fontWeight: "regular",
    size: "xs",
    variant: "title",
  },
});

export type ParagraphVariants = VariantProps<
  typeof paragraphVariants
>["variant"];

export interface ParagraphProps extends BaseUIComponentProps {
  children: ReactNode;

  variant?: ParagraphVariants;

  fontWeight?: ParagraphFontWeight;
  size?: ParagraphSize;

  as?: keyof React.JSX.IntrinsicElements | JSXElementConstructor<any>;
}

export const Paragraph = forwardRef(
  (
    {
      children,
      mode,
      fontWeight = "regular",
      size = "xs",
      variant = "title",
      as = undefined,
      className,
    }: ParagraphProps,
    ref
  ) => {
    const Component = typeof as !== "undefined" ? as : "p";

    const defaults = useGlobalDefaults(defaultBaseUIComponentProps);

    return (
      <Component
        ref={ref}
        className={cn(
          paragraphVariants({
            mode: mode ?? defaults.mode,
            fontWeight,
            size,
            variant,
          }),
          className
        )}
      >
        {children}
      </Component>
    );
  }
);

Paragraph.displayName = "Paragraph";

export const SubTitle = forwardRef(
  ({ children, ...props }: ParagraphProps, ref) => {
    return (
      <Paragraph variant="subtitle" {...props} ref={ref}>
        {children}
      </Paragraph>
    );
  }
);
