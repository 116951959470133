export type UIComponentColorMode = "light" | "dark";

export interface BaseUIComponentProps {
  mode?: UIComponentColorMode;

  className?: string;
}

export interface GlobalDefaults {
  mode: UIComponentColorMode;
}

export const defaultBaseUIComponentProps: GlobalDefaults = {
  mode: "dark",
};
