import { cva, VariantProps } from "class-variance-authority";

import {
  IconInfoCircle,
  IconBell,
  IconConfetti,
  IconAlertTriangle,
  IconCircleOff,
} from "@tabler/icons-react";

import { cn } from "@/lib/css-utils";
import { forwardRef } from "react";
import { BaseUIComponentProps, defaultBaseUIComponentProps } from "./base";
import { Paragraph } from "./paragraph";
import {
  PassModeToChildren,
  useGlobalDefaults,
  useParentMode,
} from "./provider";

export const bannerVariants = cva("p-2 border rounded-lg flex gap-2", {
  variants: {
    variant: {
      neutral: "",
      info: "",
      success: "",
      warning: "",
      error: "",
    },
    mode: {
      dark: "",
      light: "",
    },
  },

  compoundVariants: [
    {
      variant: "neutral",
      mode: "dark",
      className: "bg-neutral-900 border-neutral-800",
    },
    {
      variant: "neutral",
      mode: "light",
      className: "bg-neutral-100 border-neutral-200",
    },

    {
      variant: "info",
      mode: "dark",
      className: "bg-nInfo-900 border-nInfo-800",
    },
    {
      variant: "info",
      mode: "light",
      className: "bg-nInfo-100 border-nInfo-200",
    },

    {
      variant: "success",
      mode: "dark",
      className: "bg-nSuccess-900 border-nSuccess-800",
    },
    {
      variant: "success",
      mode: "light",
      className: "bg-nSuccess-100 border-nSuccess-200",
    },

    {
      variant: "warning",
      mode: "dark",
      className: "bg-nWarning-900 border-nWarning-800",
    },
    {
      variant: "warning",
      mode: "light",
      className: "bg-nWarning-100 border-nWarning-200",
    },

    {
      variant: "error",
      mode: "dark",
      className: "bg-nError-900 border-nError-800",
    },
    {
      variant: "error",
      mode: "light",
      className: "bg-nError-100 border-nError-200",
    },
  ],

  defaultVariants: {
    variant: "neutral",
    mode: "dark",
  },
});

const titleVariants = cva("font-medium text-xs leading-4.5", {
  variants: {
    variant: {
      neutral: "",
      info: "",
      success: "",
      warning: "",
      error: "",
    },
    mode: {
      dark: "text-neutral-100",
      light: "text-neutral-500",
    },
  },

  defaultVariants: {
    variant: "neutral",
    mode: "dark",
  },
});

const descriptionVariants = cva("font-medium text-xs leading-4.5", {
  variants: {
    variant: {
      neutral: "",
      info: "",
      success: "",
      warning: "",
      error: "",
    },
    mode: {
      dark: "text-neutral-200",
      light: "text-neutral-400",
    },
  },

  defaultVariants: {
    variant: "neutral",
    mode: "dark",
  },
});

const iconVariants = cva("w-8 h-8", {
  variants: {
    variant: {
      neutral: "text-neutral-200",
      info: "text-nInfo-500",
      success: "text-nSuccess-500",
      warning: "text-nWarning-500",
      error: "text-nError-500",
    },
    mode: {
      dark: "",
      light: "",
    },
  },

  defaultVariants: {
    variant: "neutral",
    mode: "dark",
  },
});

const getBannerIcon = (
  variant: VariantProps<typeof bannerVariants>["variant"],
  mode: VariantProps<typeof bannerVariants>["mode"],
  customIcon: React.ReactNode
) => {
  if (customIcon) {
    return customIcon;
  }

  let Element: typeof IconBell | null = null;

  switch (variant) {
    case "info":
      Element = IconInfoCircle;
      break;

    case "success":
      Element = IconConfetti;
      break;

    case "warning":
      Element = IconAlertTriangle;
      break;

    case "error":
      Element = IconCircleOff;
      break;

    default:
    case "neutral":
      Element = IconBell;
      break;
  }

  return Element ? (
    <Element
      className={iconVariants({
        variant,
        mode,
      })}
    />
  ) : null;
};

export const Banner = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    BaseUIComponentProps &
    VariantProps<typeof bannerVariants> & {
      noIcon?: boolean;
      customIcon?: React.ReactNode;
    }
>(
  (
    {
      className,
      variant = "neutral",
      customIcon,
      mode,
      noIcon,
      children,
      ...props
    },
    ref
  ) => {
    const globalDefs = useGlobalDefaults(defaultBaseUIComponentProps);

    const theMode = mode ?? globalDefs.mode;

    const icon = noIcon ? null : getBannerIcon(variant, theMode, customIcon);

    return (
      <PassModeToChildren mode={theMode}>
        <div
          {...props}
          ref={ref}
          role="alert"
          className={cn(bannerVariants({ variant, mode: theMode }), className)}
        >
          {icon}

          <div>{children}</div>
        </div>
      </PassModeToChildren>
    );
  }
);
Banner.displayName = "Banner";

export const BannerTitle = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => {
  const mode = useParentMode();

  return (
    <Paragraph
      {...props}
      mode={mode}
      as="h5"
      ref={ref}
      className={cn(titleVariants({ variant: "neutral", mode }), className)}
    >
      {children}
    </Paragraph>
  );
});
BannerTitle.displayName = "BannerTitle";

export const BannerDescription = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const mode = useParentMode();

  return (
    <Paragraph
      {...props}
      mode={mode}
      ref={ref}
      className={cn(
        descriptionVariants({ variant: "neutral", mode }),
        className
      )}
    >
      {children}
    </Paragraph>
  );
});
BannerDescription.displayName = "BannerDescription";
