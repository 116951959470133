import { createContext, useContext, useMemo } from "react";
import { BaseUIComponentProps, defaultBaseUIComponentProps } from "./base";

interface GlobalDefaults extends Pick<BaseUIComponentProps, "mode"> {}

const GlobalDefaultsContext = createContext<GlobalDefaults>({
  mode: defaultBaseUIComponentProps.mode,
});

export const GlobalDefaultsProvider = ({
  mode,
  children,
}: GlobalDefaults & { children: React.ReactNode }) => {
  const value = useMemo(() => ({ mode }), [mode]);

  return (
    <GlobalDefaultsContext.Provider value={value}>
      {children}
    </GlobalDefaultsContext.Provider>
  );
};

export const useGlobalDefaults = (defaults?: GlobalDefaults) => {
  const value = useContext(GlobalDefaultsContext);

  return value ?? defaults ?? defaultBaseUIComponentProps;
};

const ModeContext = createContext<GlobalDefaults["mode"]>(
  defaultBaseUIComponentProps.mode
);

export const PassModeToChildren = ({
  mode,
  children,
}: {
  mode: GlobalDefaults["mode"];
  children: React.ReactNode;
}) => {
  return <ModeContext.Provider value={mode}>{children}</ModeContext.Provider>;
};

export const useParentMode = () => {
  return useContext(ModeContext);
};
