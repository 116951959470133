import { PortInput } from "@/app/(protected)/(routes)/(external)/platform/freight/_components/schema";
import {
  CalculatorInputs,
  MultiModeInputForSubmit,
  PortOperationInput,
} from "@copmer/calculator-widget";

type MultiModeAnalyticsPayload = Omit<
  MultiModeInputForSubmit,
  "ports" | "useCache" | "mode" | "db_version"
> & {
  portCount: number;

  [key: `port_${number}_unlocode`]: string;
  [key: `port_${number}_name`]: number;

  [key: `port_${number}_portDA`]: number | undefined;

  [key: `port_${number}_operationCount`]: number;

  [key: `port_${number}_operation_${number}_operation`]: string;
  [key: `port_${number}_operation_${number}_amount`]: number | undefined;
  [key: `port_${number}_operation_${number}_commodity`]: string | undefined;

  [key: `port_${number}_operation_${number}_cadence`]: number | undefined;
  [key: `port_${number}_operation_${number}_terms`]: string | undefined;

  [key: `port_${number}_operation_${number}_draft`]: number | undefined;

  [key: `port_${number}_operation_${number}_tolerance`]: number | undefined;
  [key: `port_${number}_operation_${number}_commodityPrice`]:
    | number
    | undefined;

  [key: `port_${number}_operation_${number}_stowageFactor`]: number | undefined;
};

type LaxPortInput = Omit<PortInput, "operations" | "portDA"> & {
  portDA?: number | undefined;

  operations: Array<
    Omit<PortOperationInput, "cadence"> & {
      cadence: string | number | undefined;
    }
  >;
};

const getMultiModePayloadFields = (
  data: MultiModeInputForSubmit,
  portMap: Record<string, string>
): MultiModeAnalyticsPayload => {
  const portCount = data.ports.length;

  type ExtraFields = Omit<MultiModeAnalyticsPayload, "portCount" | "period">;

  const portFields: MultiModeAnalyticsPayload = {
    portCount,
    period: data.period,

    ...data.ports.reduce(
      (acc: ExtraFields, port: LaxPortInput, portIndex: number) => {
        const portKey = `port_${portIndex + 1}`;

        const baseFields = {
          [`${portKey}_unlocode`]: port.port,
          [`${portKey}_name`]: portMap[port.port ?? ""] || port.port,
          [`${portKey}_operationCount`]: port.operations.length,
          [`${portKey}_portDA`]: port.portDA,
        };

        const operationFields = port.operations.reduce(
          (acc, operation, operationIndex) => {
            const operationKey = `${portKey}_operation_${operationIndex + 1}`;

            return {
              ...acc,
              [`${operationKey}_operation`]: operation.operation,
              [`${operationKey}_amount`]: operation.amount,
              [`${operationKey}_commodity`]: operation.commodity,

              [`${operationKey}_cadence`]: operation.cadence,
              [`${operationKey}_terms`]: operation.terms,

              [`${operationKey}_draft`]: operation.draft,

              [`${operationKey}_tolerance`]: operation.tolerance,
              [`${operationKey}_commodityPrice`]: operation.commodityPrice,

              [`${operationKey}_stowageFactor`]: operation.stowageFactor,
            };
          },
          {} as ExtraFields
        );

        return {
          ...acc,
          ...baseFields,
          ...operationFields,
        };
      },

      {} as ExtraFields
    ),
  };

  return portFields;
};

type CalculatorInputsWithPortNames = CalculatorInputs & {
  load_port_name: string;
  disch_port_name: string;
};


const isCalculationInput = (data: any): data is CalculatorInputs => {
  return !!data.load_port;
};

export const convertCalculationDataToAnalyticsPayload = (
  data: CalculatorInputs | MultiModeInputForSubmit,
  portMap: Record<string, string>
) => {
  if (isCalculationInput(data)) {
    const withPortNames = {
      ...data,
      load_port_name: portMap[data.load_port] ?? data.load_port,
      disch_port_name: portMap[data.disch_port] ?? data.disch_port,
    };

    return {
      payload: withPortNames,
      ...withPortNames,
    } as CalculatorInputsWithPortNames & {
      payload: CalculatorInputsWithPortNames;
    };
  }

  const payloadFields = getMultiModePayloadFields(data, portMap);

  return {
    payload: {
      ...data,
      ports: data.ports.map((port: LaxPortInput) => ({
        ...port,
        portDA: port.portDA,
        name: portMap[port.port ?? ""] || port.port,
      })),
    },
    ...payloadFields,
  } as MultiModeAnalyticsPayload & {
    payload: MultiModeInputForSubmit;
  };
};
