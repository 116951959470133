import { cn } from "@/lib/utils";
import Icon from "./Icon";
import { valueSuffixConstructor } from "./utils";
import { Fragment, ReactNode, useContext } from "react";
import DottedLineImage from "./dotted-line.svg";
import { Button } from "@/components/ui/button";
import {
  CalcContext,
  formatNumberWithCommas,
  OperationType,
  VoyageResult,
} from "@copmer/calculator-widget";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import DebugTableDetails from "./DebugTableDetails";
import { getCurrencySymbol } from "./MobileDebugTable";
import Link from "next/link";

export const DebugTableKeyVal = ({
  label,
  value,
  className,
  valueClassName,
  labelClassName,
  grow,
}: {
  label: string | React.ReactNode;
  value: string | number | React.ReactNode;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
  grow?: "start" | "end";
}) => {
  return (
    <div className={cn("flex justify-between flex-col gap-1 -mt-1", className)}>
      {!grow || grow === "start" ? <div className="flex-grow" /> : null}
      <div
        className={cn(
          "font-light text-base text-frGrey-300 leading-none",
          valueClassName
        )}
      >
        {value}
      </div>
      <div
        className={cn("font-light text-sm text-frGrey-400 leading-none", {
          labelClassName,
        })}
      >
        {label}
      </div>
      {grow === "end" ? <div className="flex-grow" /> : null}
    </div>
  );
};

export const DebugTableOperationsHeader = ({
  result,
  isCFROptimizer,
}: {
  result: VoyageResult;
  isCFROptimizer?: boolean;
}) => {
  const items: ReactNode[] = [];

  let hasDraft = false;

  for (const portCall of result.debugData.portInfos) {
    for (const operation of portCall.operations) {
      if (operation.draft <= 25) {
        hasDraft = true;
        break;
      }
    }
  }

  let gridCols = 6;

  if (hasDraft) {
    gridCols = 7;
  }

  for (const [portIndex, portCall] of result.debugData.portInfos.entries()) {
    const isLastPort = portIndex === result.debugData.portInfos.length - 1;

    for (const [i, operation] of portCall.operations.entries()) {
      const isLastOperation = i === portCall.operations.length - 1;

      const hasCFRPriceRow =
        isCFROptimizer && operation.operation === OperationType.D;

      items.push(
        <Fragment
          key={`${portCall.UNLOCODE}${operation.operation}${operation.commodity}`}
        >
          {portIndex > 0 && i === 0 ? (
            <div
              className={cn("h-px bg-frGrey-700", {
                "col-span-6": gridCols === 6,
                "col-span-7": gridCols === 7,
              })}
            />
          ) : null}

          <DebugTableKeyVal
            valueClassName="relative"
            className={cn({
              "pb-[64px]": hasCFRPriceRow,
            })}
            label={
              operation.operation === OperationType.L ? "Load" : "Discharge"
            }
            value={
              <>
                {portCall.DisplayName}

                <div className="w-6 absolute -left-16 top-0 bg-frGrey-800">
                  {/* make sure dotted line does not go below the last icon (we hide it with this) */}
                  {isLastPort && isLastOperation ? (
                    <div className="absolute bg-frGrey-800 left-1 right-1 top-4 h-64" />
                  ) : null}

                  {/* Render circle if we are not the last port and its the first operation */}
                  {/* Render map pin if we are last port and its the last operation */}

                  {i === 0 && !isLastPort ? (
                    <Icon
                      icon="Circle"
                      className="text-frGrey-300 h-6 w-6 relative"
                    />
                  ) : isLastOperation && isLastPort ? (
                    <Icon
                      icon="MapPinFilled"
                      className="text-frGrey-300 h-6 w-6 relative"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            }
          />

          <DebugTableKeyVal
            label="Commodity"
            value={operation.commodity}
            className={cn({
              "pb-[64px]": hasCFRPriceRow,
              "col-span-2": !isCFROptimizer,
            })}
            valueClassName="break-words"
          />

          {isCFROptimizer && operation.operation === OperationType.L ? (
            <DebugTableKeyVal
              label="Optimal Load amount"
              className="col-span-2"
              value={operation.amount}
            />
          ) : null}

          {hasCFRPriceRow ? (
            <div className="col-span-2 px-2 py-1 bg-white/5 rounded-md border border-frGrey-550">
              <DebugTableKeyVal
                label="Optimal Delivery amount"
                className="col-span-2"
                value={operation.amount}
              />

              <DebugTableKeyVal
                label={`${operation.commodity} CFR Price`}
                value={valueSuffixConstructor(
                  formatNumberWithCommas(operation.cfrPrice ?? 0),
                  "$/mt"
                )}
                className="col-span-2 text-right h-[68px]"
                valueClassName="text-xl leading-6"
              />
            </div>
          ) : null}

          <DebugTableKeyVal
            label="Cadence"
            value={operation.cadence}
            className={cn({
              "pb-[64px]": hasCFRPriceRow,
            })}
          />

          <DebugTableKeyVal
            label="Terms"
            value={operation.terms}
            className={cn({
              "pb-[64px]": hasCFRPriceRow,
            })}
          />

          {operation.draft <= 25 ? (
            <DebugTableKeyVal
              label="Draft"
              value={valueSuffixConstructor(
                formatNumberWithCommas(operation.draft),
                "m"
              )}
              valueClassName="whitespace-nowrap"
              className={cn({
                "pb-[64px]": hasCFRPriceRow,
              })}
            />
          ) : hasDraft ? (
            <div />
          ) : null}

          {isCFROptimizer ? null : (
            <DebugTableKeyVal
              label="Port DA"
              value={valueSuffixConstructor(
                formatNumberWithCommas(operation.portDA),
                getCurrencySymbol(operation.portDACurrency)
              )}
              valueClassName="whitespace-nowrap"
            />
          )}

          <div
            className={cn("", {
              "col-span-6": gridCols === 6,
              "col-span-7": gridCols === 7,
            })}
          />
        </Fragment>
      );
    }
  }

  return (
    <div
      className={cn(
        "w-full grid gap-y-2 gap-x-10 relative pl-16 overflow-y-hidden",
        {
          "grid-cols-6": gridCols === 6,
          "grid-cols-7": gridCols === 7,
        }
      )}
    >
      <div
        className="absolute top-5 bottom-4 left-0 pointer-none w-6 mt-1.5"
        style={{
          background: `url(${DottedLineImage.src}) repeat-y center top`,
        }}
      />
      {items}
    </div>
  );
};

const DefaultWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={cn("py-4 w-full flex justify-center", className)}>
    <div className="w-full max-w-3xl flex items-center justify-between">
      {children}
    </div>
  </div>
);

const OperationsListRenderer = ({
  result,
  calculationId,
  isCFROptimizer,
}: {
  result: VoyageResult;
  calculationId: string;
  isCFROptimizer?: boolean;
}) => (
  <>
    <DebugTableOperationsHeader
      result={result}
      isCFROptimizer={isCFROptimizer}
    />

    <div className="flex items-center justify-center pt-3">
      <Link href={`/platform/freight/${calculationId}/${result.id}/map`}>
        <Button
          variant="icon"
          size="iconText"
          className="text-primary-200 hover:text-primary-100 py-px px-px hover:border-transparent"
        >
          <Icon icon="Map" className="h-6 w-6 mr-2" />
          Map
        </Button>
      </Link>
    </div>
  </>
);

const FieldOrder: {
  [key: string]: Array<
    | {
        type: "KeyVal";
        children: Array<{
          label: string;
          labelNode?: () => ReactNode;
          suffix: string;
          valueFrom: (props: { result: VoyageResult }) => number;
          className?: string;
        }>;
        wrapper: typeof DefaultWrapper;
      }
    | {
        type: "operationsList";
        children: typeof OperationsListRenderer;
        wrapper: typeof DefaultWrapper;
      }
  >;
} = {
  regular: [
    {
      type: "KeyVal",
      children: [
        {
          label: "Bunker (VLSFO cost)",
          suffix: "$/MT",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.bunkerVlsfoCost,
        },
        {
          label: "Bunker (LSMGO cost)",
          suffix: "$/MT",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.bunkerLsmgoCost,
        },
        {
          label: "Applicable CO2 EU ETS",
          suffix: "MTS",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.applicableCO2EmittedWithinEUETS,
        },
      ],
      wrapper: ({ children }: { children: ReactNode }) => (
        <DefaultWrapper className="border-frGrey-700 border-b">
          {children}
        </DefaultWrapper>
      ),
    },
    {
      type: "operationsList",
      wrapper: ({ children }: { children: ReactNode }) => (
        <div className="border-frGrey-700 border-b py-4 w-full flex justify-center">
          <div className="w-full max-w-6xl">{children}</div>
        </div>
      ),

      children: OperationsListRenderer,
    },
    {
      type: "KeyVal",
      wrapper: DefaultWrapper,
      children: [
        {
          label: "Bunker VLSFO",
          suffix: "MTS",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.totalCost.bunkersInMts.vlsfo,
        },
        {
          label: "Bunker LSMGO",
          suffix: "MTS",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.totalCost.bunkersInMts.lsmgo,
        },
        {
          label: "Total days",
          suffix: "",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.totalCost.costInDays,
        },
        {
          label: "EU ETS carbon mts",
          suffix: "",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.applicableCO2EmittedWithinEUETS,
        },
        {
          label: "Total cost",
          suffix: "$",
          valueFrom: ({ result }: { result: VoyageResult }) =>
            result.debugData.totalCost.sumInDollars ?? 0,
          className: "min-w-[75px]",
        },
      ],
    },
  ],
  cfr: [
    {
      type: "operationsList",
      wrapper: ({ children }: { children: ReactNode }) => (
        <div className="border-frGrey-700 border-b py-4 w-full flex justify-center">
          <div className="w-full max-w-6xl">{children}</div>
        </div>
      ),

      children: OperationsListRenderer,
    },
    {
      type: "KeyVal",
      wrapper: ({ children }: { children: ReactNode }) => (
        <div className="border-frGrey-700 border-b py-4 w-full flex justify-center">
          <div className="w-full max-w-[68rem] flex items-center justify-between">
            {children}
          </div>
        </div>
      ),

      children: [
        {
          label: "Bunker (VLSFO cost)",
          suffix: "$/MT",
          valueFrom: ({ result }) => result.debugData.bunkerVlsfoCost,
        },
        {
          label: "Bunker (LSMGO cost)",
          suffix: "$/MT",
          valueFrom: ({ result }) => result.debugData.bunkerLsmgoCost,
        },
        {
          label: "Hire",
          suffix: "$",
          valueFrom: ({ result }) => result.debugData.hire.value,
        },
        {
          label: "Ballast Bonus",
          suffix: "$",
          valueFrom: ({ result }) => result.debugData.ballastBonus,
        },
        {
          label: "Total cost",
          suffix: "$",
          valueFrom: ({ result }) =>
            result.debugData.totalCost.sumInDollars ?? 0,
          className: "min-w-[75px]",
        },
      ],
    },
    {
      type: "KeyVal",
      wrapper: DefaultWrapper,
      children: [
        {
          label: "Bunker VLSFO",
          suffix: "MTS",
          valueFrom: ({ result }) =>
            result.debugData.totalCost.bunkersInMts.vlsfo,
        },
        {
          label: "Bunker LSMGO",
          suffix: "MTS",
          valueFrom: ({ result }) =>
            result.debugData.totalCost.bunkersInMts.lsmgo,
        },
        {
          label: "Applicable CO2 EU ETS",
          labelNode: () => (
            <>
              Applicable CO<sub>2</sub> EU ETS
            </>
          ),
          suffix: "MTS",
          valueFrom: ({ result }) =>
            result.debugData.applicableCO2EmittedWithinEUETS,
        },
        {
          label: "EU ETS carbon price",
          suffix: "$/MT",
          valueFrom: ({ result }) => result.debugData.etsPrice,
        },
      ],
    },
  ],
};

export const DebugTableHeader = ({
  result,
  calculationId,
}: {
  result: VoyageResult;
  calculationId: string;
}) => {
  let renderRules = FieldOrder.regular;

  const ctx = useContext(CalcContext);

  if (ctx?.isCFROptimizer) {
    renderRules = FieldOrder.cfr;
  }

  const body = renderRules.map((rule, index) => {
    switch (rule.type) {
      case "operationsList":
        return (
          <rule.wrapper key={index}>
            <rule.children
              result={result}
              calculationId={calculationId}
              isCFROptimizer={!!ctx?.isCFROptimizer}
            />
          </rule.wrapper>
        );

      case "KeyVal": {
        const children = rule.children.map((child, index) => {
          const theValue = formatNumberWithCommas(child.valueFrom({ result }));

          return (
            <div key={child.label}>
              <DebugTableKeyVal
                label={child.labelNode ? child.labelNode() : child.label}
                value={
                  child.suffix
                    ? valueSuffixConstructor(theValue, child.suffix)
                    : theValue
                }
                className={child.className}
              />
            </div>
          );
        });

        return <rule.wrapper key={index}>{children}</rule.wrapper>;
      }
    }
  });

  return (
    <div className="w-full">
      {body}

      <Accordion type="single" collapsible>
        <AccordionItem
          value={`${result.id}-details`}
          key={result.id}
          className={cn(
            "border-0 max-lg:hidden [&[data-state=open]]:bg-frGrey-990 -mx-8 px-8 -mb-4 rounded-b-lg",
            "[&[data-state=open]_.closed-label]:hidden [&[data-state=open]_.open-label]:inline"
          )}
        >
          <AccordionContent className="pb-0">
            <DebugTableDetails result={result} />
          </AccordionContent>
          <AccordionTrigger
            icon={false}
            underline={false}
            className="py-0 border-t border-frGrey-700"
          >
            <Button variant="darkText" className="px-0 text-primary-200">
              <Icon icon="ChevronDown" className="w-4 h-4 mr-2" />
              <span>
                <span className="inline closed-label">See </span>
                <span className="hidden open-label">Close </span>
                <span>detailed view</span>
              </span>
            </Button>
          </AccordionTrigger>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default function DebugTable({
  result,
  calculationId,
}: {
  result: VoyageResult;
  calculationId: string;
}) {
  return (
    <div className="bg-frGrey-800 rounded-b-lg px-8 py-4">
      <DebugTableHeader result={result} calculationId={calculationId} />
    </div>
  );
}
